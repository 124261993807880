import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Image } from 'react-bootstrap';

import '../../../App.css';

export default function HorseTableRow({ horse }) {
  function clickLink() {
    if (horse) {
      return '/horses/manage/' + horse.id;
    }
    return '/horses';
  }

  function getSaleHorseText(horse) {
    if (horse && horse.isSaleHorse) {
      return 'Yes';
    }
    return 'No';
  }

  function getGenderText(horse) {
    if (horse) {
      if (horse.gender === 0) return 'No Gender Selected';
      if (horse.gender === 1) return 'Gelding';
      if (horse.gender === 2) return 'Mare';
      if (horse.gender === 3) return 'Stallion';
    }
    return '';
  }

  function getGeneralInfo() {
    return (
      <div>
        <div>ID: {horse.id}</div>
        <div>{getGenderText(horse)}</div>
        <div>{horse.breed}</div>
        <div>{horse.color}</div>
        <div>Sale Horse: {getSaleHorseText(horse)}</div>
      </div>
    );
  }

  function renderImgTag(photoURL) {
    if (photoURL && photoURL.length > 0) {
      return <Image src={photoURL} className="mh-50" rounded fluid />;
    } else {
      return (
        <Image src="/images/photos/blank.png" alt="Avatar" rounded responsive />
      );
    }
  }

  function getHorsePhoto() {
    if (horse && horse.photoURL && horse.photoURL.length > 1) {
      return <div>{renderImgTag(horse.photoURL)}</div>;
    }
    return (
      <div>
        <Image
          src="/images/horses/horse_avatar_thumb.jpg"
          alt="Avatar"
          rounded
        />
      </div>
    );
  }

  return (
    <LinkContainer to={clickLink()}>
      <tr>
        <td>
          <h3>{getHorsePhoto()}</h3>
        </td>
        <td>
          <h3 className="card-header-text">{horse.name}</h3>
        </td>
        <td>
          <h4 className="card-header-text">{getGeneralInfo()}</h4>
        </td>
      </tr>
    </LinkContainer>
  );
}
