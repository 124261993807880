/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  ButtonToolbar,
  Badge,
} from 'react-bootstrap';
import { Person } from 'react-bootstrap-icons';
import { fetchUser } from '../../actions';
import { LinkContainer } from 'react-router-bootstrap';
/* eslint-enable no-unused-vars */

class Dashboard extends Component {
  componentDidMount() {
    this.props.fetchUser();
  }

  getDisplayName() {
    switch (this.props.auth) {
      case null:
        return 'Awaiting user';
      case false:
        return 'No user';
      default:
        return this.props.auth.displayName;
    }
  }

  getID() {
    switch (this.props.auth) {
      case null:
        return '...';
      case false:
        return '...';
      default:
        return '(User ID: ' + this.props.auth.id + ')';
    }
  }

  renderLoginIcon() {
    if (this.props.auth) {
    }
  }

  renderTrainerEditItem() {
    switch (this.props.auth) {
      case null:
        return;
      case false:
        return;
      default:
        if (this.props.hasTrainer) {
          return (
            <LinkContainer to={'/account/trainer'}>
              <Button variant="danger">Trainer Info</Button>
            </LinkContainer>
          );
        } else return;
    }
  }

  render() {
    return (
      <Container>
        <Row className="show-grid">
          <Col xs={12} sm={3} md={4}>
            <Card variant="danger">
              <Card.Header>
                <Card.Title    >
                  {this.renderLoginIcon()} User Logged In
                </Card.Title>
              </Card.Header>
              <Card.Body>
                {this.getDisplayName()}
                {'   '}
                {this.getID()}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={9} md={8}>
            <Card variant="danger">
              <Card.Header>
                <Card.Title    >
                  <Person /> User Tools
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <ButtonToolbar>
                  <LinkContainer to={'/account/edit'}>
                    <Button variant="info">Edit Info</Button>
                  </LinkContainer>
                  <LinkContainer to={'/initiateresetpass'}>
                    <Button variant="danger">Reset Password</Button>
                  </LinkContainer>
                </ButtonToolbar>
                <hr></hr>
                <ButtonToolbar>
                  <LinkContainer to={'/horses'}>
                    <Button variant="info">
                      Your Horses&nbsp;&nbsp;
                      <Badge variant="light">
                        4
                        {/* {Object.keys(this.props.horses).length} */}
                      </Badge>
                    </Button>
                  </LinkContainer>
                </ButtonToolbar>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

// function checkHasPermission(auth, permission) {
//   if (auth && auth.perms) {
//     return auth.perms.findIndex((x) => x.permission === permission) > -1;
//   }
//   return false;
// }

function mapStateToProps({
  auth,
}) {
  return {
    auth,
  };
}

export default connect(mapStateToProps, { fetchUser })(Dashboard);
