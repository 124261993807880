import React from 'react';
import { Navbar, Nav, NavDropdown, Container, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { logoutUser } from '../actions';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function Navigation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);

  function renderLoginNavItem() {
    // COLOR comes through putting gold on Nav.  But not FontSize.
    // FONTSIZXE comes through on .Item, but not color.  No idea why.
    return (
      <Nav className="justify-content-end nav-item-text-gold">
        <LinkContainer to={'/login'}>
          <NavDropdown.Item className="nav-item-text-gold">
            {process.env.REACT_APP_VERSION}
          </NavDropdown.Item>
        </LinkContainer>
      </Nav>
    );
  }

  function handleClick() {
    dispatch(logoutUser(navigate));
  }

  function renderUserMenuNavItem() {
    switch (auth) {
      case null:
        return;
      case false:
        return;
      default:
        return (
          <NavDropdown title="Account">
            <LinkContainer to={'/account'}>
              <NavDropdown.Item>Account</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to={'/horses'}>
              <NavDropdown.Item>Horses</NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Item onClick={handleClick}>Logout</NavDropdown.Item>
          </NavDropdown>
        );
    }
  }

  function renderMenuItems() {
    return (
      <Nav className="m-auto">
        <LinkContainer to={'/'}>
          <Nav.Item className="nav-item-text-gold m-1">Home</Nav.Item>
        </LinkContainer>
        <Nav.Item className="nav-item-text-gold m-1 d-none d-lg-block">|</Nav.Item>
        <LinkContainer to={'/sales'}>
          <Nav.Item className="nav-item-text-gold m-1">Horse Sales</Nav.Item>
        </LinkContainer>
        <Nav.Item className="nav-item-text-gold m-1 d-none d-lg-block">|</Nav.Item>
        <LinkContainer to={'/training'}>
          <Nav.Item className="nav-item-text-gold m-1">
            Training/Clinics
          </Nav.Item>
        </LinkContainer>
        <Nav.Item className="nav-item-text-gold m-1 d-none d-lg-block">|</Nav.Item>
        <LinkContainer to={'/resume'}>
          <Nav.Item className="nav-item-text-gold m-1">Tom's Resume</Nav.Item>
        </LinkContainer>
        <Nav.Item className="nav-item-text-gold m-1 d-none d-lg-block">|</Nav.Item>
        <LinkContainer to={'/rates'}>
          <Nav.Item className="nav-item-text-gold m-1">Rates</Nav.Item>
        </LinkContainer>
        <Nav.Item className="nav-item-text-gold m-1 d-none d-lg-block">|</Nav.Item>
        <LinkContainer to={'/contactus'}>
          <Nav.Item className="nav-item-text-gold m-1">Contact Us</Nav.Item>
        </LinkContainer>
      </Nav>
    );
  }

  return (
    <Navbar
      collapseOnSelect="true"
      bg="dark"
      variant="dark"
      expand="lg"
      sticky="top"
    >
      <Container fluid>
        <Navbar.Brand>
          <LinkContainer to={'/'}>
            <Image src="/images/logo1_120pxH.png" rounded></Image>
          </LinkContainer>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="nav-item-text-gold"
        />
        <Navbar.Collapse>
          {renderMenuItems()}
          {renderUserMenuNavItem()}
          {renderLoginNavItem()}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
