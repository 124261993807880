import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchHorse,
  createHorse,
  updateHorse,
} from '../../../actions/horseActions';
import HorseSubForm from './core/HorseSubForm';
import { useNavigate, useParams } from 'react-router-dom';

export default function HorseForm(props) {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(fetchHorse(id));
    }
  }, [dispatch, id]);
  
  const selHorse = useSelector((state) => state.horses[id]);
  const selBarns = useSelector((state) => state.barns);

  function navigateBack() {
    if (id)
      navigate('/horses/manage/' + id);
    else
      navigate('/horses');
  }

  function saveHorse(horse) {
    if (horse && horse.id) {
      return dispatch(updateHorse(horse)).then(() => {
        navigateBack();
      });
    } else {
      return dispatch(createHorse(horse)).then(() => {
        navigateBack();
      });
    }
  }

  return (
    <HorseSubForm
      horse={selHorse}
      barns={selBarns}
      submitHorse={saveHorse}
      auth={props.auth}
    />
  );
}