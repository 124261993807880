import React, { useEffect } from 'react';
import { fetchHorsePhotos } from '../../../../actions/horseActions';
import _ from 'lodash';
import HorsePhotoCard from './HorsePhotoCard';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

export default function HorsePhotosGroup(props) {

  const photos = useSelector(state => state.horsePhotos);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.horseId) {
      dispatch(fetchHorsePhotos(props.horseId));
    }
  }, [dispatch, props.horseId]);


  function renderHorsePhotos() {
    if (photos) {
      return _.values(photos).map((horsePhoto) => {
        return (
          <tr key={horsePhoto.id} >
            <HorsePhotoCard
              horsePhoto={horsePhoto}
              onDelete={props.onDelete}
              onMakeMainPhoto={props.onMakeMainPhoto}
            />
          </tr>
        );
      });
    }
    return null;
  }

  return (
    <Table striped bordered hover>
      <tbody>{renderHorsePhotos()}</tbody>
    </Table>
  );

}

