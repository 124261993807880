/* eslint-disable no-unused-vars */
import React from 'react';
import { ListGroupItem, Card, Button, ListGroup, Image } from 'react-bootstrap';
import { getGenderText } from '../../../../core/HorseTools';
import { LinkContainer } from 'react-router-bootstrap';

import '../../../App.css';
import { cardTheme, defaultButtonTheme } from '../../../core/Styling.js';

export default function HorseDetailsPanel(props) {
  function getDescription() {
    if (props.horse) {
      return props.horse.description;
    }
    return '';
  }

  function formatAsDollars(dollarValue) {
    if (dollarValue) {
      let formatting_options = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      };
      let dollarString = new Intl.NumberFormat('en-US', formatting_options);
      return dollarString.format(dollarValue);
    }
  }

  function getGeneralInformation() {
    if (props.horse) {
      return (
        <ListGroup>
          <ListGroupItem className="card-content-text">Description: {getDescription()}</ListGroupItem>
          <ListGroupItem className="card-content-text">
            Price: {formatAsDollars(props.horse.price)}
          </ListGroupItem>
          <ListGroupItem className="card-content-text">Breed: {props.horse.breed}</ListGroupItem>
          <ListGroupItem className="card-content-text">
            Gender: {getGenderText(props.horse.gender)}
          </ListGroupItem>
          <ListGroupItem className="card-content-text">Color: {props.horse.color}</ListGroupItem>
          <ListGroupItem className="card-content-text">Height: {props.horse.height}</ListGroupItem>
          <ListGroupItem className="card-content-text">Birth Year: {props.horse.birthYear}</ListGroupItem>
          <ListGroupItem>
            <Image height="200" src={props.horse.photoURL} />
          </ListGroupItem>
        </ListGroup>
      );
    }
    return <ListGroup></ListGroup>;
  }

  function getEditButton() {
    var route = '';
    if (props.horse && props.horse.id) route = '/horses/edit/' + props.horse.id;
    return (
      <LinkContainer to={route}>
        <Button variant={defaultButtonTheme}>Edit</Button>
      </LinkContainer>
    );
  }

  return (
    <Card bg={cardTheme}>
      <Card.Header>
        <h1 className="card-header-text">Details &nbsp;&nbsp;{getEditButton()}</h1>
      </Card.Header>
      <Card.Body>{getGeneralInformation()}</Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
}
