/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Card, Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import moment from 'moment';
import Linkify from 'react-linkify';
import ModalImage from 'react-modal-image';
import { LinkContainer } from 'react-router-bootstrap';
import _ from 'lodash';
/* eslint-enable no-unused-vars */

export default function MediaItem({ media, auth }) {
  function getMediaContent(media) {
    if (media && media.content) {
      return (
        <ListGroup>
          <ListGroupItem>{media.content}</ListGroupItem>
        </ListGroup>
      );
    }
  }

  function getUserName(media) {
    if (media && media.displayName) {
      return media.displayName;
    }
  }

  function dateToText(date) {
    return <p>{moment(date).format('M/D/YY - hh:mm')}</p>;
  }

  function getMediaContentDate(media) {
    if (media && media.createdAt) {
      return dateToText(media.createdAt);
    }
  }

  function getEditLink(media) {
    if (media) {
      return '/media/edit/' + media.id;
    }
  }

  function getEditButton(media, auth) {
    if (auth && media.userId === auth.id) {
      return (
        <LinkContainer to={getEditLink(media)}>
          <Button className="pull-right" variant="info">
            <Pencil />
          </Button>
        </LinkContainer>
      );
    }
  }

  function panelizePhotos(photos) {
    if (photos) {
      return _.values(photos).map((photo) => {
        return (
          <div key={photo.id}>
            <ModalImage
              small={photo.amazons3URLHalf}
              medium={photo.amazons3URLHalf}
              large={photo.amazons3URL}
              alt="Hello World!"
              key={photo.id}
              padding="15px"
              className="rounded mx-auto d-block"
            />
            <hr></hr>
          </div>
        );
      });
    }
    return null;
  }

  function getMediaPhotos(media) {
    if (media && media.photos && media.photos.length > 0) {
      return (
        <ListGroup>
          <ListGroupItem variant="info">
            <Card.Body>{panelizePhotos(media.photos)}</Card.Body>
          </ListGroupItem>
        </ListGroup>
      );
    }
  }

  // function getPanelStyle(media) {
  //   if (media) {
  //     if (media.type === 0) {
  //       return 'primary';
  //     }
  //     if (media.type === 1) {
  //       return 'danger';
  //     }
  //     if (media.type === 2) {
  //       return 'warning';
  //     }
  //   } else {
  //     return 'primary';
  //   }
  // }

  return (
    <Col xs={12} md={12}>
      <Card bg="dark">
        <Card.Header className="nav-item-text">
          <Card.Title>{getUserName(media)}</Card.Title>
        </Card.Header>
        <Card.Body className="nav-item-text">
          <Linkify>{getMediaContent(media)}</Linkify>
        </Card.Body>
        {getMediaPhotos(media)}
        <Card.Footer className="nav-item-text">
          {getEditButton(media, auth)}
          {getMediaContentDate(media)}
        </Card.Footer>
      </Card>
    </Col>
  );
}

MediaItem.propTypes = {
  media: PropTypes.object.isRequired,
};
