import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { loginUser } from '../../actions';
import { Button } from 'react-bootstrap';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Required!'),
  password: Yup.string().min(5, 'Required!').required('Required!'),
  // confirmPassword: Yup.string()
  //   .required("PleaseConfirm your password")
  //   .oneOf([Yup.ref("password")], "Passwords do not match"),
});

export default function LoginFormik() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onSubmit(values) {
    dispatch(
      loginUser(
        {
          username: values.email,
          password: values.password,
        },
        navigate,
      )
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="form-controlFormik">
          <label htmlFor="email">E-mail</label>
          <Field
            className="textFieldFormik"
            type="email"
            id="email"
            name="email"
          />
          <ErrorMessage name="email" />
        </div>

        <div className="form-controlFormik">
          <label htmlFor="password">Password</label>
          <Field
            className="textFieldFormik"
            type="password"
            id="password"
            name="password"
          />
          <ErrorMessage name="password" />
        </div>

        <Button variant="primary" type="submit">
          Login
        </Button>
      </Form>
    </Formik>
  );
}
