/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import { ListCheck, PlusCircle } from 'react-bootstrap-icons';
import { LinkContainer } from 'react-router-bootstrap';
import { fetchMedias } from '../../../actions/mediaActions';
import MediaItemGroup from './core/MediaItemGroup';
import _ from 'lodash';
/* eslint-enable no-unused-vars */

class MediasManage extends Component {
  componentDidMount() {
    this.props.fetchMedias();
  }

  renderButtonToolBar() {
    return (
      <div padding="150px">
        <LinkContainer to={'/media/new'}>
          <Button variant="primary">
            <PlusCircle />
            &nbsp;&nbsp;Add Media
          </Button>
        </LinkContainer>
        {'     '}
        <LinkContainer to={'/'}>
          <Button variant="primary">
            <ListCheck />
            &nbsp;&nbsp;Main Feed
          </Button>
        </LinkContainer>
      </div>
    );
  }

  renderGoToMainFeed() {
    return (
      <div>
        <LinkContainer to={'/'}>
          <Button variant="primary">
            <ListCheck />
            &nbsp;&nbsp;Main Feed
          </Button>
        </LinkContainer>
      </div>
    );
  }

  render() {
    return (
      <Container>
        <Row className="show-grid">
          <Col xs={12} sm={3} md={4}>
            <Card variant="danger">
              <Card.Header>
                <Card.Title    >UnSocial Media</Card.Title>
              </Card.Header>
              <Card.Body>{this.renderButtonToolBar()}</Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={9} md={8}>
            <MediaItemGroup
              mediaFeed={this.props.medias}
              auth={this.props.auth}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

function sortedMedias(medias) {
  if (medias) {
    let sortedMedias = _.orderBy(medias, ['createdAt'], ['desc']);
    return sortedMedias;
  }
  return null;
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  medias: sortedMedias(state.medias),
});

export default connect(mapStateToProps, { fetchMedias })(MediasManage);
