import axios from 'axios';
import {
//   FETCH_USER,
//   ADD_HORSE,
  HORSE_FETCHED,
  SET_HORSES,
  SET_HORSE_DOCUMENTS,
  SET_HORSE_PHOTOS,
  SET_HORSE_VIDEOS,
  HORSE_DOCUMENT_FETCHED,
//   HORSE_PHOTO_FETCHED,
  HORSE_VIDEO_FETCHED,
} from './types';

// export function addHorse(horse) {
//   return {
//     type: ADD_HORSE,
//     horse,
//   };
// }

export function horseFetched(horse) {
  return {
    type: HORSE_FETCHED,
    horse,
  };
}

export function setHorses(horses) {
  return {
    type: SET_HORSES,
    horses,
  };
}

export function setHorseDocuments(horseDocuments) {
  return {
    type: SET_HORSE_DOCUMENTS,
    horseDocuments,
  };
}

export function horseDocumentFetched(horseDocument) {
  return {
    type: HORSE_DOCUMENT_FETCHED,
    horseDocument,
  };
}

export function setHorsePhotos(horsePhotos) {
  return {
    type: SET_HORSE_PHOTOS,
    horsePhotos,
  };
}

// export function horsePhotoFetched(horsePhoto) {
//   return {
//     type: HORSE_PHOTO_FETCHED,
//     horsePhoto,
//   };
// }

export function setHorseVideos(horseVideos) {
  return {
    type: SET_HORSE_VIDEOS,
    horseVideos,
  };
}

export function horseVideoFetched(horseVideo) {
  return {
    type: HORSE_VIDEO_FETCHED,
    horseVideo,
  };
}

// export const submitHorse = (values, history) => async (dispatch) => {
//   const res = await axios.post('/api/horses/create', values);
//   history.push('/horses');
//   dispatch({ type: FETCH_USER, payload: res.data });
// };

export const fetchHorses = () => async (dispatch) => {
  const res = await axios.get('/api/horses/read');
  dispatch(setHorses(res.data));
};

// export const fetchHorse = (id) => async (dispatch) => {
//   const res = await axios.get('/api/horses/read/' + id);
//   dispatch(horseFetched(res.data));
// };

export const fetchHorse = (id) => async (dispatch) => {
  const res = await axios.get('/api/salehorses/' + id);
  console.log('fetchHorse');
  console.log(res.data);
  dispatch(horseFetched(res.data));
};

export const createHorse = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/create', values);
  dispatch(fetchHorse(res.data.id));
};

export const updateHorse = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/update', values);
  dispatch(fetchHorse(res.data.id));
};

export const addDocToHorse = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/docs/upload', values);
  dispatch(horseDocumentFetched(res.data));
};

export const addPhotoToHorse = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/photos/new/multi', values);
  dispatch(setHorsePhotos(res.data));
};

export const addVideoToHorse = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/videos/new', values);
  dispatch(horseVideoFetched(res.data));
};

export const setMainPhotoForHorse = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/photos/setmain', values);
  dispatch(horseFetched(res.data));
};

export const deleteHorsePhoto = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/photos/delete', values);
  dispatch(setHorsePhotos(res.data));
};

export const deleteHorseVideo = (values) => async (dispatch) => {
  await axios.post('/api/horsevideos/delete', values);
  dispatch(fetchHorseVideos());
};

export const fetchHorseDocuments = (horseId) => async (dispatch) => {
  const res = await axios.get('/api/horses/read/documents/forhorse/' + horseId);
  dispatch(setHorseDocuments(res.data));
};

export const fetchHorsePhotos = (horseId) => async (dispatch) => {
  const res = await axios.get('/api/horses/read/photos/forhorse/' + horseId);
  console.log('returned horse photos data');
  console.log(res.data);
  dispatch(setHorsePhotos(res.data));
};

export const fetchHorseVideos = (horseId) => async (dispatch) => {
  const res = await axios.get('/api/horses/read/videos/forhorse/' + horseId);
  dispatch(setHorseVideos(res.data));
};
