/* eslint-disable no-unused-vars */
import { combineReducers } from 'redux';
import authReducer from './authReducer';
import horsesReducer from './horses/horsesReducer';
import horseDocumentsReducer from './horses/horseDocumentsReducer';
import horsePhotosReducer from './horses/horsePhotosReducer';
import horseVideosReducer from './horses/horseVideosReducer';
import mediasReducer from './mediasReducer';
import mediaFeedReducer from './mediaFeedReducer';
import usersSearchReducer from './usersSearchReducer';

export default combineReducers({
  auth: authReducer,
  horses: horsesReducer,
  horseDocuments: horseDocumentsReducer,
  horsePhotos: horsePhotosReducer,
  horseVideos: horseVideosReducer,
  medias: mediasReducer,
  mediaFeed: mediaFeedReducer,
  searchedUsers: usersSearchReducer,
});
