import React from 'react';
import { Card, Button, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { cardTheme, defaultButtonTheme } from '../../../core/Styling.js';

export default function SaleHorseCard(props) {
  function getSalesHorseRoute() {
    if (props.horse) {
      return '/sales/' + props.horse.id;
    }
    return '';
  }

  return (
    <Card bg={cardTheme} style={{ maxWidth: '600px', height: '100%' }}>
      <Link to={getSalesHorseRoute()}>
        <Card.Img
          as={Image}
          fluid={true}
          variant="top"
          src={props.horse.photoURL}
        />
      </Link>
      <Card.Body>
        <Card.Header>
          <h2 className="card-header-text">{props.horse.name}</h2>
        </Card.Header>
        <Card.Text>
          <h5 className="card-content-text">{props.horse.description}</h5>
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <LinkContainer to={getSalesHorseRoute()}>
          <Button variant={defaultButtonTheme}>More Info</Button>
        </LinkContainer>
      </Card.Footer>
    </Card>
  );
}
