import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import _ from 'lodash';
import SaleHorseCard from './core/SaleHorseCard';

import { useSelector } from 'react-redux';

export default function SaleHorsesPage() {

  const horses = useSelector((state) => state.horses);

  function renderHorses() {
    return _.values(horses).map((horse) => {
      if (horse) {
        return <Col sm={12} md={6} lg={6} xl={4} className="mb-3" ><SaleHorseCard horse={horse} key={horse.id} /></Col>;
      } else {
        return null;
      }
    });
  }

  return <Container fluid><Row lg={3}>
  {renderHorses()}</Row></Container>;
}
