import React from 'react';
import {
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
  Container,
} from 'react-bootstrap';

import '../App.css';
import { cardTheme } from '../core/Styling.js';

export default function Rates() {
  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme}>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <h3 className="card-header-text">
                    Rates
                  </h3>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br></br>
      <Row className="pb-3">
        <Col md={12} lg={12}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header className="nav-item-text">
              <Card.Title>
                <h2 className="card-header-text">
                  Tom Murray Dressage Rate Sheet
                </h2>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <h5 className="card-content-text">Single Lesson: $85</h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">Package of 10: $750</h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">Show Fees: $75</h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Hauling to shows can be provided for an additional fee.
                    (Braiding needs to be arranged by client)
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Clinics: $800/day plus travel & expenses
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Horse Sales: 10% Commission
                  </h5>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
