import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import LoginFormik from '../../../formik/LoginFormik';
import { useNavigate } from 'react-router-dom';

export default function LoginSubForm(props) {
  const navigate = useNavigate();

  return (
    <Card variant="success">
      <Card.Header>
        <Card.Title>{props.title}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Card variant="warning">
          <Card.Body>
            <LoginFormik history={navigate} />
          </Card.Body>
          <Card.Footer>
            <h2>Never logged in?</h2>
            <LinkContainer to={'/createaccount'}>
              <Button variant="info">Create Account</Button>
            </LinkContainer>
          </Card.Footer>
        </Card>
      </Card.Body>
      <Card.Footer>
        <h2>Need to reset your password?</h2>
        <LinkContainer to={'/initiateresetpass'}>
          <Button variant="danger">Reset Password</Button>
        </LinkContainer>
      </Card.Footer>
    </Card>
  );
}
