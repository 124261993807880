import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as URLS from '../es5Routes';
import { fetchUser } from '../actions';
import Navigation from './Navigation';
import AccountCreated from './customers/account/AccountCreated';
import CreateAccount from './customers/account/CreateAccount';
import Dashboard from './customers/Dashboard';
import InvalidToken from './customers/account/InvalidToken';
import Landing from './Landing';
import NewLogin from './customers/account/NewLogin';
import LoginInvalid from './customers/account/LoginInvalid';
import InitiateResetPass from './customers/account/InitiateResetPass';
import ResetPass from './customers/account/ResetPass';
import ResetSent from './customers/account/ResetSent';
import MediaFormPage from './customers/media/MediaFormPage';
import MediasManage from './customers/media/MediasManage';
import UserAdmin from './admin/users/UserAdmin';
import UserEditFormPage from './customers/account/UserEditFormPage';
import UserAdminEditFormPage from './admin/users/UserAdminEditFormPage';

import HorsesPage from './customers/horses/HorsesPage';
import HorseForm from './customers/horses/HorseForm';
import HorseManagement from './customers/horses/HorseManagement';

import SaleHorsePage from './customers/horses/SaleHorsePage';
import SaleHorsesPage from './customers/horses/SaleHorsesPage';
import { fetchHorses } from '../actions/horseActions';

import Clinics from './simple/Clinics';
import Rates from './simple/Rates';
import Resume from './simple/Resume';
import ContactUs from './simple/ContactUs';
import './App.css';

class App extends Component {
  componentDidMount() {
    this.props.fetchUser();
    this.props.fetchHorses();
  }

  render() {
    return (
      <BrowserRouter>
        <Container>
          <Navigation />
          <br />
          <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route path={'/training'} element={<Clinics />} />
            <Route path={'/resume'} element={<Resume />} />
            <Route path={'/rates'} element={<Rates />} />
            <Route path={'/contactus'} element={<ContactUs />} />
            <Route path={URLS.LOGIN} element={<NewLogin />} />
            <Route
              path={'/initiateresetpass'}
              element={<InitiateResetPass />}
            />
            <Route path={'/resetpass/:token'} element={<ResetPass />} />
            <Route path={'/resetsent'} element={<ResetSent />} />
            <Route path={'/invalidtoken'} element={<InvalidToken />} />
            <Route path={'/invalidlogin/:reason'} element={<LoginInvalid />} />
            {/* <Route path={URLS.LOGIN} element={<Login} />/> */}

            <Route path={'/createaccount'} element={<CreateAccount />} />
            <Route path={'/accountcreated'} element={<AccountCreated />} />

            <Route exact path={'/account'} element={<Dashboard />} />
            <Route exact path="/account/edit" element={<UserEditFormPage />} />
            
            <Route exact path="/horses" element={<HorsesPage />} />
            <Route path="/horses/edit/:id" element={<HorseForm />} />
            <Route path={"/horses/new"} element={<HorseForm />} />
            <Route path="/horses/manage/:id" element={<HorseManagement />} />
            
            <Route exact path="/sales" element={<SaleHorsesPage />} />
            <Route path="/sales/:id" element={<SaleHorsePage />} />

            <Route path={'/media/new'} element={<MediaFormPage />} />
            <Route path={'/media/edit/:id'} element={<MediaFormPage />} />
            <Route path={'/media'} element={<MediasManage />} />

            {/* Admin */}

            <Route exact path="/users" element={<UserAdmin />} />
            <Route path="/users/edit/:id" element={<UserAdminEditFormPage />} />
          </Routes>
        </Container>
      </BrowserRouter>
    );
  }
}

function checkAdmin(auth) {
  if (auth && auth.perms) {
    return auth.perms.findIndex((x) => x.permission === 'AEGON') > -1;
  }
  return false;
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  isAdmin: checkAdmin(state.auth),
});

export default connect(mapStateToProps, {
  fetchUser, fetchHorses,
})(App);
