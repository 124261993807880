import React from 'react';
import {
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
  Container,
  Image,
} from 'react-bootstrap';

import '../App.css';
import { cardTheme } from '../core/Styling.js';

export default function ContactUs(props) {
  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <h3 className="card-header-text">Contact Us</h3>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Body>
              <Image
                className="rounded mx-auto d-block"
                src="/images/contacts.jpeg"
                rounded
                fluid
              />
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title className="card-header-text">Tom Murray</Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Cell Phone - (626) 644-3008
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Email - tommurraydressage@gmail.com
                  </h5>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title className="card-header-text">
                Steven Schachter
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Cell Phone - (213) 434-3704
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Email - sydnyk2@icloud.com
                  </h5>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title className="card-header-text">
                Mailing Address
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup className="mb-3">
                <ListGroupItem>
                  <h5 className="card-content-text">440 Coleman Bridge Rd</h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">Aiken, SC 29805</h5>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
