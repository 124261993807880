/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { fetchHorseVideos } from '../../../../actions/horseActions';
import _ from 'lodash';
import HorseVideoCard from './HorseVideoCard';
import { Table, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
/* eslint-enable no-unused-vars */

export default function HorseVideosGroup(props) {
  const documents = useSelector((state) => state.horseVideos);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.horseId) {
      dispatch(fetchHorseVideos(props.horseId));
    }
  }, [dispatch, props.horseId]);

  function renderHorseVideos() {
    if (documents) {
      return _.values(documents).map((horseVideo) => {
        return (
          <tr key={horseVideo.id}>
            <HorseVideoCard
              horseVideo={horseVideo}
              onDelete={props.onDeleteVideo}
            />
          </tr>
        );
      });
    }
    return null;
  }

  return (
    <Col md={12} lg={12} xl={8} xxl={6}>
      <Table striped bordered hover>
        <tbody>{renderHorseVideos()}</tbody>
      </Table>
    </Col>
  );
}
