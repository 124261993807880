/* AUTO GEN */
/* mediasReducer.js */
import { SET_MEDIAS, MEDIA_FETCHED } from '../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case MEDIA_FETCHED:
      return { ...state, [action.media.id]: action.media };
    case SET_MEDIAS:
      const newPosts = _.mapKeys(action.medias, 'id');
      return { ...newPosts };
    default:
      return state;
  }
}
