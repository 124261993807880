/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { LinkContainer } from 'react-router-bootstrap';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
/* eslint-enable no-unused-vars */

function convertUTCToLocalDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return date;
}

function convertLocalToUTCDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  return date;
}

// export default function UTCDatePicker({
//   startDate,
//   endDate,
//   selected,
//   onChange,
//   ...props
// }) {
//   return (
//     <DatePicker
//       startDate={convertUTCToLocalDate(startDate)}
//       endDate={convertUTCToLocalDate(endDate)}
//       selected={convertUTCToLocalDate(selected)}
//       onChange={date => onChange(convertLocalToUTCDate(date))}
//       {...props}
//     />
//   )
// }

const DatePickerAdapter = ({ input: { onChange, value }, ...rest }) => (
  <DatePicker
    selected={convertUTCToLocalDate(value)}
    onChange={(date) => onChange(convertLocalToUTCDate(date))}
    {...rest}
  />
);

export function okCancelButtons(route) {
  return (
    <ButtonToolbar>
      <LinkContainer to={route}>
        <Button variant="warning">Cancel</Button>
      </LinkContainer>
      <Button type="submit" variant="primary">
        Save
      </Button>
    </ButtonToolbar>
  );
}

export function okButton() {
  return (
    <ButtonToolbar>
      <Button type="submit" variant="primary">
        Save
      </Button>
    </ButtonToolbar>
  );
}

export function okButtonText(text) {
  return (
    <ButtonToolbar>
      <Button type="submit" variant="primary">
        {text}
      </Button>
    </ButtonToolbar>
  );
}

export function renderFormTitle(object, title) {
  if (object && object.id > 0) {
    return (
      <div>
        <h1>Edit {title}</h1>
      </div>
    );
  } else {
    return (
      <div>
        <h1>Add {title}</h1>
      </div>
    );
  }
}

export const required = (value) => (value ? undefined : 'Required');
export const mustBeNumber = (value) =>
  isNaN(value) ? 'Must be a number' : undefined;

export const minValue = (min) => (value) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

export const maxValue = (max) => (value) =>
  isNaN(value) || value <= max ? undefined : `Should be greater than ${max}`;

export const maxLength = (max) => (value) =>
  value.length <= max ? undefined : `Should be shorter than ${max} characters`;

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export function finalDateField(name, label, validation) {
  return (
    <div>
      <label>{label}</label>
      <Field
        name={name}
        validate={validation}
        dateFormat="yyyy/MM/dd"
        component={DatePickerAdapter}
      ></Field>
    </div>
  );
}

export function finalTextField(name, label, validation) {
  return (
    <Field
      name={name}
      validate={validation}
      allowNull
      parse={(value) => (value === null ? '' : value)}
    >
      {({ input, meta }) => (
        <div>
          <label>{label}</label>
          <input {...input} type="text" placeholder={label} />
          {meta.error && meta.touched && <span>{meta.error}</span>}
        </div>
      )}
    </Field>
  );
}

export function finalPasswordField(name, label, validation) {
  return (
    <Field
      name={name}
      validate={validation}
      allowNull
      parse={(value) => (value === null ? '' : value)}
    >
      {({ input, meta }) => (
        <div>
          <label>{label}</label>
          <input {...input} type="password" placeholder={label} />
          {meta.error && meta.touched && <span>{meta.error}</span>}
        </div>
      )}
    </Field>
  );
}

export function finalTextArea(name, label, validation) {
  return (
    <div>
      <label>{label}</label>
      <Field
        name={name}
        component="textarea"
        validate={validation}
        parse={(value) => (value === null ? '' : value)}
      />
    </div>
  );
}

//export function finalTextArea(name, label, validation) {
//  return (
//    <Field name={name} validate={validation} parse={(value) => (value === null ? '' : value)}>
//      {({ textarea, meta }) => (
//        <div>
//          <label>{label}</label>
//          <textarea {...textarea} placeholder={label} />
//          {meta.error && meta.touched && <span>{meta.error}</span>}
//        </div>
//      )}
//    </Field>
//  );
//}

export function finalBoolField(name, label) {
  return (
    <div>
      <label>
        <Field name={name} component="input" type="checkbox" />
        &nbsp;&nbsp;&nbsp;
        {label}
      </label>
    </div>
  );
}

export function finalObjSelector(name, label, optionObjects) {
  if (optionObjects && optionObjects.length > 0) {
    return (
      <div>
        <label>{label}</label>
        <Field name={name} component="select">
          {optionObjects.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.key}
              </option>
            );
          })}
        </Field>
      </div>
    );
  } else {
    return '';
  }
}

export function photoUploader() {
  return (
    <div>
      <label>Photo</label>
      <input id="photos" type="file" name="photos" multiple />
    </div>
  );
}

export function fileUploader() {
  return (
    <div>
      <label>File</label>
      <input id="fileUpload" type="file" name="fileUpload" multiple />
      {/*<Form.Control id="file" type="file" name="file" multiple />*/}
    </div>
  );
}

export function createOptionsList(idArray) {
  var zeroExists = false;
  if (idArray) {
    let array = _.map(idArray, (idobj) => {
      if (parseInt(idobj.id) === 0) {
        zeroExists = true;
      }
      return {
        value: idobj.id,
        key: idobj.name,
      };
    });
    if (!zeroExists) {
      array.splice(0, 0, { value: 0, key: 'None' });
    }
    return array;
  }
  return null;
}
