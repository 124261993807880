/* eslint-disable no-unused-vars */
import React from 'react';
/* eslint-enable no-unused-vars */

const AccountCreated = () => {
  return (
    <div>
      <h1>Account Created!!</h1>
      <h3>Please proceed to Login to log into the new account!</h3>
    </div>
  );
};

export default AccountCreated;
