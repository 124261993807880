import axios from 'axios';
import { FETCH_USER } from './types';
import { API_CURRENT_USER } from '../es5Routes';
// import { fetchHorses } from './horseActions';

export const fetchUser = () => async (dispatch) => {
  const res = await axios.get(API_CURRENT_USER);
  dispatch({ type: FETCH_USER, payload: res.data });
      // dispatch(fetchHorses());
  // if (res.data) {
    // if (res.data.perms) {
      // if (checkHasPermission(res.data.perms, 'AEGON')) {
        // dispatch(fetchEvents());
      // }
      // if (checkHasPermission(res.data.perms, 'ADD_HORSES')) {
      // }
      // }
      // dispatch(fetchCommunityUsers());
    // }
  // }
};

export const loginUser = (values, navigate) => async (dispatch) => {
  const res = await axios.post('/api/auth/login/', values);
  if (res && res.data && res.data === 'PASSWORD_INCORRECT') {
    navigate('/invalidlogin/pw');
  } else if (res && res.data && res.data === 'ACCOUNT_LOCKED') {
    navigate('/invalidlogin/locked');
  } else if (res && res.data && res.data === 'USER_NOT_FOUND') {
    navigate('/invalidlogin/user');
  } else {
    dispatch(fetchUser());
    navigate('/account');
  }
};

export const initiatePasswordReset = (values, history) => async (dispatch) => {
  await axios.post('/api/auth/startreset/', values);
  history.push('/resetsent');
};

export const resetPassword = (values, navigate) => async (dispatch) => {
  let response = await axios.post('/api/auth/reset/', values);
  if (response.data === 'PASSWORD_UPDATED') {
    navigate('/login');
  } else {
    navigate('/invalidtoken');
  }
};

export const logoutUser = (navigate) => async (dispatch) => {
  await axios.get('/api/auth/logout');
  navigate('/');
  dispatch({ type: FETCH_USER, payload: null });
};
