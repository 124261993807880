

export function getGenderText(gender) {
  if (gender) {
    if (gender === 0) return 'No Gender Selected';
    if (gender === 1) return 'Gelding';
    if (gender === 2) return 'Mare';
    if (gender === 3) return 'Stallion';
  }
  return '';
}