import React from 'react';
import {
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
  Container,
} from 'react-bootstrap';
import ModalImage from 'react-modal-image';

import '../App.css';
import { cardTheme } from '../core/Styling.js';

export default function Clinics() {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme}>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <h2 className="card-header-text">
                    Clinics
                  </h2>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br></br>
      <Row className="pb-3">
        <Col md={12} lg={12}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header className="nav-item-text">
              <Card.Title>
                <h3 className="card-header-text">Tom is Available for Clinics</h3>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <ModalImage
                      small="/images/clinic.jpg"
                      medium="/images/clinic.jpg"
                      large="/images/clinic.jpg"
                      alt="Hello World!"
                      key={1}
                      padding="15px"
                    />
                    <br></br>
                  </div>
                </ListGroupItem>
                <ListGroupItem>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <h3 className="card-header-text">OHSET Clinic, 2018</h3>
                  </div>
                </ListGroupItem>
                <ListGroupItem>
                  <h4 className="card-content-text">
                    "Tom brought a toolbox full of tools to share... What makes
                    Tom unique is the energy he exudes as he instructs ..all of
                    whom proclaimed their session was 'amazing' and some
                    considered it the best lesson they ever had. They left with
                    a 'can do' feeling about themselves and what they could
                    accomplish with their horses."
                  </h4>{' '}
                  <h3 className="card-header-text">-- Umpqua Valley Chapter Clinic Series</h3>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
            <Card.Footer>
              <ListGroup>
                <ListGroupItem>
                  <h3 className="card-header-text">Scheduling Contact:</h3>{' '}
                  <h4 className="card-content-text">Stephen Schacter - (213) 434-3704&nbsp;&nbsp;</h4>
                </ListGroupItem>
              </ListGroup>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
