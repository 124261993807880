/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { fetchHorseDocuments } from '../../../../actions/horseActions';
import _ from 'lodash';
import HorseDocumentCard from './HorseDocumentCard';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
/* eslint-enable no-unused-vars */

export default function HorseDocumentGroup(props) {

  const documents = useSelector(state => state.horseDocuments);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.horseId) {
      dispatch(fetchHorseDocuments(props.horseId));
    }
  }, [dispatch, props.horseId]);


  function renderHorseDocuments() {
    if (documents) {
      return _.values(documents).map((horseDocument) => {
        return (
          <tr key={horseDocument.id} >
            <HorseDocumentCard
              horseDocument={horseDocument}
              onDelete={null}
              onMakeMainDocument={null}
            />
          </tr>
        );
      });
    }
    return null;
  }

  return (
    <Table striped bordered hover>
      <tbody>{renderHorseDocuments()}</tbody>
    </Table>
  );

}

