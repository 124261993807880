/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import HorseTableRow from './HorseTableRow';
import { Table } from 'react-bootstrap';

import { useSelector } from 'react-redux';

import '../../../App.css';

export default function HorseTable(props) {
  const auth = useSelector((state) => state.auth);

  function renderHorses() {
    return _.values(props.filteredHorses).map((horse) => {
      if (horse) {
        return <HorseTableRow horse={horse} key={horse.id} auth={auth} />;
      } else {
        return null;
      }
    });
  }

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th><h4 className="card-header-text">Photo</h4></th>
          <th><h4 className="card-header-text">Name</h4></th>
          <th><h4 className="card-header-text">Info</h4></th>
        </tr>
      </thead>
      <tbody>{renderHorses()}</tbody>
    </Table>
  );
}
