/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addVideoToHorse, deleteHorseVideo, 
} from '../../../../actions/horseActions';
import {
  Form,
  Card,
  Button,
} from 'react-bootstrap';
import {
  textField,
} from '../../../core/CoreForms';
import HorseVideosGroup from './HorseVideosGroup';
import { PlusCircle } from 'react-bootstrap-icons';

const cardTheme = 'dark';
const buttonTheme = 'light';

class HorseVideoManagementPanel extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      id: this.props.horse ? this.props.horse.id : null,
      userId: this.props.horse ? this.props.horse.userId : null,
      videoTitle: '',
      videoDescription: '',
      videoURL: '',
      errors: '',
      loading: false,
      adding: false,
    };
    this.handleDeleteVideo = this.handleDeleteVideo.bind(this);
    this.handleSubmitVideo = this.handleSubmitVideo.bind(this);
  }

  handleClick = (e) => {
    this.setState({
      adding: !this.state.adding,
    });
  }

  handleDeleteVideo = ({ videoId }) => {
    this.props
      .deleteHorseVideo({
        horseId: this.props.horseId,
        horseVideoId: videoId,
      });
  };

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmitVideo = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!(this.props.horse && this.props.horse.id)) {
      return;
    }
    const { videoTitle, videoDescription, videoURL } = this.state;
    this.setState({ videoTitle: '', videoDescription: '', videoURL: '', loading: true, adding: false });
    this.props
      .addVideoToHorse({
        horseId: this.props.horse.id,
        title: videoTitle,
        description: videoDescription,
        URL: videoURL,
        userId: this.props.auth.id,
      })
      .catch((err) =>
        err.response
          .json()
          .then(({ errors }) => this.setState({ errors, loading: false }))
      );
  };

  getForm() {
    if (this.state.adding) {
      return (
        <div>
          <h3>Add Video</h3>
          <Form
            onSubmit={this.handleSubmitVideo}
          >
            {textField('videoTitle', 'Video Title', this)}
            {textField('videoDescription', 'Video Description', this)}
            {textField('videoURL', 'Video URL', this)}
            <Button type="submit" variant="info">
              Submit
            </Button>
          </Form>
        </div>
      )
    }
  }

  /* eslint-disable max-len */
  render() {
    var videosPanel;

    if (this.props.horse) {
      videosPanel = <HorseVideosGroup horseId={this.props.horse.id} onDeleteVideo={this.handleDeleteVideo} />;
    } else {
      videosPanel = <label>DocPanelLabel</label>;
    }

    return (
      <Card bg={cardTheme}>
        <Card.Header>
          <h2>Videos&nbsp;&nbsp;
            <Button variant={buttonTheme} onClick={this.handleClick}>
              <PlusCircle />
            </Button>
          </h2>
        </Card.Header>
        <Card.Body>
          {this.getForm()}
        </Card.Body>
        <Card.Footer>
          {videosPanel}
        </Card.Footer>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  addVideoToHorse, deleteHorseVideo,
})(HorseVideoManagementPanel);