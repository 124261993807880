/* AUTO GEN */
/* horse_documentsReducer.js */
import { SET_HORSE_DOCUMENTS, HORSE_DOCUMENT_FETCHED } from '../../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case HORSE_DOCUMENT_FETCHED:
      return { ...state, [action.horseDocument.id]: action.horseDocument };
    case SET_HORSE_DOCUMENTS:
      const newPosts = _.mapKeys(action.horseDocuments, 'id');
      return { ...newPosts };
    default:
      return state;
  }
}
