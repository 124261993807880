import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import LoginSubForm from './core/LoginSubForm';

export default function LoginInvalid() {

  function getTitle() {  
    const { reason } = useParams();
    if (reason) {
      if (reason === 'pw') {
        return 'Invalid Password, Please Try Again';
      }
      if (reason === 'user') {
        return 'Email/User Not Found, Please Try Again';
      }
    }
    return 'Login to System';
  }

  return (
    <Container>
      <Row className="show-grid">
        <Col xs={12} sm={12} md={12}>
          <LoginSubForm title={getTitle()}/>
        </Col>
      </Row>
    </Container>
  );
}
