import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LoginSubForm from './core/LoginSubForm';

export default function NewLogin() {

  return (
    <div>
      <Row className="show-grid">
        <Col xs={12} sm={6} md={6}>
          <LoginSubForm title="Login to System" />
        </Col>
      </Row>
    </div>
  );
}
