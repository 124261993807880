/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { searchUsers } from '../../../actions/userActions';
import classnames from 'classnames';
import _ from 'lodash';
import {
  Button,
  ButtonToolbar,
  Col,
  Form,
  Card,
  Container,
  Row,
} from 'react-bootstrap';
import UserList from './UserList';
/* eslint-enable no-unused-vars */

class UserAdmin extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      searchString: '',
      errors: '',
      loading: false,
    };
  }

  componentDidMount = () => {
    // this.props.fetchAdminBarns();
  };

  handleChange = (e) => {
    if (e.target) {
      this.setState({ [e.target.id]: e.target.value });
    }
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { searchString } = this.state;
    this.setState({ loading: true });
    this.props
      .searchUsers(searchString)
      .catch((err) =>
        err.response
          .json()
          .then(({ errors }) => this.setState({ errors, loading: false }))
      );
  };

  searchToolBar() {
    return (
      <ButtonToolbar>
        <Button type="submit" variant="primary">
          Search
        </Button>
      </ButtonToolbar>
    );
  }

  render() {
    const searchForm = (
      <Form
        className={classnames('ui', 'form', { loading: this.state.loading })}
        onSubmit={this.handleSubmit}
      >
        <div>
          <h1>Search Users</h1>
        </div>
        <Form.Group>
          <Form.Label>User Search</Form.Label>
          <Form.Control
            id="searchString"
            type="text"
            value={this.state.searchString}
            onChange={this.handleChange}
            name="searchString"
          />
          <Form.Control.Feedback />
          <Form.Text>Search by name or email (partial is ok).</Form.Text>
        </Form.Group>
        <div>{this.searchToolBar()}</div>
      </Form>
    );
    return (
      <Container>
        <Row className="show-grid">
          <Col xs={12} sm={12} md={12}>
            <Card variant="danger">
              <Card.Header>
                <Card.Title    >Users</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card variant="danger">
                  <Card.Header>{searchForm}</Card.Header>
                  <Card.Body>
                    <UserList users={this.props.searchedUsers} />
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    /* eslint-disable max-len */
    // Below is hoe to get an item out of a pure Array.
    //  workorder: state.workorders.find((item) => item._id === props.match.params._id),
    searchedUsers: state.searchedUsers,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { searchUsers })(UserAdmin);
