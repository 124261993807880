import {
  SEARCH_CLEAR_USERS,
  SEARCH_CLEAR_USER,
  SEARCH_SET_USERS,
  SEARCH_USER_FETCHED,
} from '../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEARCH_CLEAR_USERS:
      return INITIAL_STATE;
    case SEARCH_CLEAR_USER:
      delete state[action.searchUser.id];
      return state;
    case SEARCH_USER_FETCHED:
      // action.payload === { _id: 'svsdv', workorderName: 'Bob' ... }
      return { ...state, [action.user.id]: action.user };
    case SEARCH_SET_USERS:
      // action.payload === [ workorder1, workorder2, workorder3 ]
      const newPosts = _.mapKeys(action.users, 'id');
      // So this clears EVERY Search, otherwise, the user search form will
      // show the results of every search performed.
      return { ...newPosts };
    default:
      return state;
  }
}
