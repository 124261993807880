import React, { useState } from 'react';
import {
  Card,
  Container,
  Row,
  Col,
  ButtonToolbar,
  Button,
} from 'react-bootstrap';
import { PlusCircle, ListCheck } from 'react-bootstrap-icons';
import HorseTable from './core/HorseTable';
import { LinkContainer } from 'react-router-bootstrap';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import '../../App.css';
import { cardTheme, defaultButtonTheme } from '../../core/Styling.js';

export default function HorsePage() {
  const [showAll, setShowAll] = useState(false);
  const [filterText, setFilterText] = useState('');

  const horses = useSelector((state) => state.horses);

  function filterArchivedHorses(filterArchived) {
    if (filterArchived) {
      let filteredHorses = _.filter(horses, function (o) {
        return !o.dataStatus;
      });
      return filteredHorses;
    } else return horses;
  }

  function filterHorses(filterArchived) {
    var horseList = filterArchivedHorses(filterArchived);
    if (filterText.length > 0) {
      let filter = filterText;
      let filteredTextHorses = _.filter(horseList, function (o) {
        return o.name.toLowerCase().includes(filter.toLowerCase());
      });
      return filteredTextHorses;
    } else return horseList;
  }

  function handleFilterTextChange(e) {
    if (e.target) {
      setFilterText(e.target.value);
    }
  }

  function handleDisplayChange(e) {
    setShowAll(!showAll);
  }

  function getDisplayText() {
    if (showAll) {
      return 'Hide Archived Horses';
    }
    return 'Show All Horses';
  }

  function getUnarchivedHorseCountText() {
    let filteredHorses = filterHorses(true);
    if (showAll)
      return 'Unarchived Horses: (' + Object.keys(filteredHorses).length + ')';
    else
      return (
        '--> Unarchived Horses: (' + Object.keys(filteredHorses).length + ')'
      );
  }

  function getTotalHorseCountText() {
    if (showAll)
      return '--> Total Horses: (' + Object.keys(horses).length + ')';
    else return 'Total Horses: (' + Object.keys(horses).length + ')';
  }

  function renderDisplayAllButton() {
    return (
      <Button variant={defaultButtonTheme} onClick={handleDisplayChange}>
        <ListCheck />
        &nbsp;&nbsp;&nbsp;
        {getDisplayText()}
      </Button>
    );
  }

  return (
    <Container fluid>
      <Row className="show-grid">
        <Col xs={12} sm={12} md={12}>
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title><h2 className="card-header-text">Horses</h2></Card.Title>
            </Card.Header>
            <Card.Body>
              <ButtonToolbar>
                <LinkContainer to={'/horses/new'}>
                  <Button variant={defaultButtonTheme} className="me-2">
                    <PlusCircle />
                  </Button>
                </LinkContainer>
                {renderDisplayAllButton()}
              </ButtonToolbar>
              {getUnarchivedHorseCountText()}
              <br></br>
              {getTotalHorseCountText()}
              <br></br>
            </Card.Body>
          </Card>
          <Card variant={cardTheme}>
            <Card.Body>
              <label>
                Search Name :
                <input
                  type="text"
                  value={filterText}
                  onChange={handleFilterTextChange}
                />{' '}
              </label>
              <HorseTable filteredHorses={filterHorses(!showAll)} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
