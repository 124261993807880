import React from 'react';
import {
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
  Container,
  Image,
} from 'react-bootstrap';
import ReactPlayer from 'react-player';

import '../App.css';
import { cardTheme } from '../core/Styling.js';

const videoUrl = 'https://youtu.be/pB8Sb7IwNrQ';
// 'https://www.youtube.com/watch?v=DuCZ8_y0KpI&ab_channel=sydnyk';

export default function Resume() {
  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <h3 className="card-header-text">
                    Resume
                  </h3>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={6} xxxl={6}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>
                <h2 className="card-header-text">
                  Tom Murray: A Life With Horses
                </h2>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem variant="info">
                  <Image
                    src="/images/TMD_LOGO_350px.jpg"
                    className="rounded mx-auto d-block"
                  />
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={6} xxxl={6}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>
                <h2 className="card-header-text">Gran Prix Video</h2>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem variant="info">
                  <ReactPlayer
                    url={videoUrl}
                    className="rounded mx-auto d-block"
                  />
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col md={6} lg={6}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>
                <h2 className="card-header-text">Some Accomplishments</h2>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Tom Murray and his horse, Balanchine, finished the 2022 show
                    season 9th in the USDF open Grand Prix for KWPN horses. He
                    is a bronze, silver and gold medal rider competing in Grand
                    Prix dressage for the last 10 years. He teaches at Viola
                    Farm in Aiken, where he and his partner breed and raise
                    Dutch and Hanoverian horses.
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    2022- Tryon Open Grand Prix Winner (65.65)
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    2019 – 5 Star Dressage Rider Centerlinescores.com
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    2019 ODS Champion Grand Prix
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    2018 ODS Champion Intermediare 2
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    2016 Qualified (placed 9th) in National Prix St. George
                    Finals in Lexington Ky.
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    1ST place Intermediare I at Kentucky Horse Park, 2016
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    2016 Reserve Champion Prix St. Georges Finals, Devonwood
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    2016 – selected for the 2016 region VI adult clinic demo
                    rider at Prix St. George
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    2016 – 65% Prix St George, “Balanchine”
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    2015 – 5 Star Dressage Rider Centerlinescores.com
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    2013 – Grand Prix Dressage Gold Medal
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    2012 – DASC Intermediare I Year End Champion
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    2009 – Bronze & Silver Medal
                  </h5>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={6}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>
                <h2 className="card-header-text">
                  Trained With (Partial List)
                </h2>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Ulf Wadeborn & Lars Holmberg (3 yrs.)
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Judy Bergquist (2 ½ yrs.)
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">David Blake (2 ½ yrs.)</h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Rudolph Zeilinger (8 mos. In Germany)
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Charlotte Bredahl (2 yrs.)
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">Tina Stewart (2 yrs.)</h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">Heather Oleson (2 yrs)</h5>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col md={6} lg={6}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>
                <h2 className="card-header-text">List of Clinicians</h2>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Betsy Steiner & Kathy Connelly, Charlotte Bredahl, Lisa
                    Wilcox, Jeremy Steinberg, Bo Tibblin, Juan Matute,
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Conrad Schumacher, Hilda Gurney, Charles de Kunffy, Melonie
                    Kessler, Christian Schacht, Catherine Haddad,
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Mette Rosencrantz, Jan Ebeling, Gerd Schonebeck, Nick
                    Wagman, Verne Batchelder, Lilo Fore, Kamila DuPont
                  </h5>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={6}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>
                <h2 className="card-header-text">
                  Additional Equine Education Experience
                </h2>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Colorado State University Equine Reproduction
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Practiced embryo transfers successfully
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    In hand stallion and mare approvals for Hanoverian,
                    Oldenburg & Holsteiner
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Stallion marketing and videos
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Designed, created, built and managed 4 private boarding
                    facilities
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Bred and foaled over 50 mares and imprinting
                  </h5>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col md={6} lg={6}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>
                <h2 className="card-header-text">Horse Training Highlights</h2>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    “Silver Lining” – trained & rode from 4 yr old to 3rd level
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    “Decadance” – trained & rode from 3 yr. old to I1 Champion
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    “Balanchine” – trained & rode from 5 yr old green broke to
                    Grand Prix
                  </h5>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={6}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>
                <h2 className="card-header-text">Hunter/Jumper Experience</h2>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Started competing in hunter/jumpers at the age of 8.
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Won the LAHSA Adult Amateur Medal Finals.
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Won and placed in numerous AO jumper classes to 4’6 on the A
                    circuit.
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    4th place in IJF 5 Yr. Old Jumper Finals
                  </h5>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col md={6} lg={6}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>
                <h2 className="card-header-text">Biographical Highlights</h2>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    2007 Pasadena Foothill Board of Realtors Historic
                    Achievement Award
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Historic renovations including house relocations
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Pasadena CA “top 10 realtor” for 8 years
                  </h5>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={6}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>
                <h2 className="card-header-text">References</h2>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Ulf Wadeborn: (trainer) 818. 919. 02810
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Judy Bergquist: (trainer) 805. 531. 5482
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Charlotte Bayley Schindelholz (216) 337-8045
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Lara Anderson (803) 215-6590
                  </h5>
                </ListGroupItem>
                <ListGroupItem>
                  <h5 className="card-content-text">
                    Daniel Brown (803) 295-2421
                  </h5>
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
