import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHorse } from '../../../actions/horseActions';
import {
  Card,
  Button,
  Container,
  Row,
  Tab,
  Tabs,
  Badge,
} from 'react-bootstrap';
import { Easel } from 'react-bootstrap-icons';
import { LinkContainer } from 'react-router-bootstrap';
import HorseDocumentManagementPanel from './core/HorseDocumentManagementPanel';
import HorseVideoManagementPanel from './core/HorseVideoManagementPanel';
import HorsePhotoManagementPanel from './core/HorsePhotoManagementPanel';
import HorseDetailsPanel from './core/HorseDetailsPanel';
import { useParams } from 'react-router-dom';

import '../../App.css';
import { cardTheme, defaultButtonTheme } from '../../core/Styling.js';

export function HorseManagement() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchHorse(id));
    }
  }, [dispatch, id]);

  const horse = useSelector((state) => state.horses[id]);
  const horsePhotos = useSelector((state) => state.horsePhotos);
  const horseDocuments = useSelector((state) => state.horseDocuments);
  const horseVideos = useSelector((state) => state.horseVideos);

  function getPhotosCount() {
    if (horsePhotos) return Object.keys(horsePhotos).length;
    else return 0;
  }

  function getDocumentsCount() {
    if (horseDocuments) return Object.keys(horseDocuments).length;
    else return 0;
  }

  function getVideosCount() {
    if (horseVideos) return Object.keys(horseVideos).length;
    else return 0;
  }

  function getPhotosTabTitle() {
    return (
      <React.Fragment>
        Photos &nbsp;
        <Badge bg="danger">{getPhotosCount()}</Badge>
      </React.Fragment>
    );
  }

  function getVideosTabTitle() {
    return (
      <React.Fragment>
        Videos &nbsp;
        <Badge bg="success">{getVideosCount()}</Badge>
      </React.Fragment>
    );
  }

  function getDocumentsTabTitle() {
    return (
      <React.Fragment>
        Documents &nbsp;
        <Badge bg="info">{getDocumentsCount()}</Badge>
      </React.Fragment>
    );
  }

  function renderBackButton() {
    return (
      <LinkContainer to={'/horses'}>
        <Button variant={defaultButtonTheme}>Return to Horses</Button>
      </LinkContainer>
    );
  }

  return (
    <Container fluid>
      <Row className="show-grid mb-2">
        <Card bg={cardTheme}>
          <Card.Header>
            <h2 className="card-header-text">
              <Easel /> &nbsp; &nbsp;Horse Management Console
            </h2>
            <h3 className="card-header-text">
              {horse ? horse.name : ''}&nbsp; -&nbsp; (Id:
              {horse ? horse.id : ''})
            </h3>
          </Card.Header>
          <Card.Footer>
            {renderBackButton()}
          </Card.Footer>
        </Card>
      </Row>
      <Row className="show-grid">
        <Card bg={cardTheme}>
          <Card.Body>
            <Tabs
              defaultActiveKey="details"
              id="uncontrolled-tab-example"
              className="mb-3"
              variant={cardTheme}
            >
              <Tab eventKey="details" title="Details">
                <HorseDetailsPanel horse={horse} />
              </Tab>
              <Tab eventKey="photos" title={getPhotosTabTitle()}>
                <HorsePhotoManagementPanel horse={horse} />
              </Tab>
              <Tab eventKey="videos" title={getVideosTabTitle()}>
                <HorseVideoManagementPanel horse={horse} />
              </Tab>
              <Tab eventKey="documents" title={getDocumentsTabTitle()}>
                <HorseDocumentManagementPanel horse={horse} />
              </Tab>
              {/*  {feedForm}*/}
            </Tabs>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default HorseManagement;
