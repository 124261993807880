/* AUTO GEN */
/* horse_videosReducer.js */
import { SET_HORSE_VIDEOS, HORSE_VIDEO_FETCHED } from '../../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case HORSE_VIDEO_FETCHED:
      return { ...state, [action.horseVideo.id]: action.horseVideo };
    case SET_HORSE_VIDEOS:
      const newPosts = _.mapKeys(action.horseVideos, 'id');
      return { ...newPosts };
    default:
      return state;
  }
}
