import { SET_HORSES, HORSE_FETCHED, SET_HORSE_PHOTOS } from '../../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {};
let holder = {};
let holderid = -1;

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_HORSE_PHOTOS:
      if (state && Object.keys(state).length === 0) {
        if (action.horsePhotos && action.horsePhotos.length > 0) {
          holder = _.mapKeys(action.horsePhotos, 'id');
          holderid = action.horsePhotos[0].horseId;
        }
      }
      if (action.horsePhotos && action.horsePhotos.length > 0) {
        let horse = state[action.horsePhotos[0].horseId];
        if (horse) {
          horse.horsePhotos = action.horsePhotos;
        }
      }
      return state;
    case HORSE_FETCHED:
      return { ...state, [action.horse.id]: action.horse };
    case SET_HORSES:
      const newPosts = _.mapKeys(action.horses, 'id');
      if(Object.keys(holder).length > 0 && holderid > 0) {
        if(newPosts && Object.keys(newPosts).length > 0) {
          let horse = newPosts[holderid];
          if (horse) {
            horse.horsePhotos = holder;
          }
          holderid = -1;
          holder = {};
        }
      }
      return { ...newPosts };
    default:
      return state;
  }
}
