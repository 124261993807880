/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { fetchMediaFeed } from '../actions/mediaActions';
import {
  Card,
  Col,
  Container,
  Image,
  Row,
  Carousel,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';
import imgMyimageexample from '../assets/gazebo_1000px.jpg';
import { cardTheme } from './core/Styling.js';

const divStyle = {
  width: '88%',
  height: '800px',
  backgroundImage: `url(${imgMyimageexample})`,
  backgroundSize: 'cover',
};


class Landing extends Component {
  render() {
    return (
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="mb-3">
            {/* <Image
              fluid
              className="rounded mx-auto d-block"
              src={imgMyimageexample}
            /> */}
            <Carousel fade controls={false} className="mb-3">
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/images/horse_vertical_logo_W1000px.png"
                  alt="First slide"
                  height="100%"
                />
              </Carousel.Item>
            </Carousel>
            <Carousel fade controls={false} className="mb-3">
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/images/gazeebo_1000pxw.jpg"
                  alt="First slide"
                  height="100%"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="rounded mx-auto d-block">
            <Card bg={cardTheme}>
              <Card.Body>
                <ListGroup>
                  <ListGroupItem>
                    <h5 className="card-content-text">
                      Tom Murray Dressage/Viola Farm, Aiken, S.C. is a boutique
                      breeding operation and dressage training facility.
                    </h5>
                  </ListGroupItem>
                  <ListGroupItem>
                    <h5 className="card-content-text">
                      We incorporate the finest champion German Hanoverian blood
                      lines with over 30 years of breeding experience.
                    </h5>
                  </ListGroupItem>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { fetchMediaFeed })(Landing);
