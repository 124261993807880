/* AUTO GEN */
/* horse_photosReducer.js */
import { SET_HORSE_PHOTOS } from '../../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    // case HORSE_PHOTO_FETCHED:
    //   return { ...state, [action.horsePhoto.id]: action.horsePhoto };
    case SET_HORSE_PHOTOS:
      const newPosts = _.mapKeys(action.horsePhotos, 'id');
      return { ...newPosts };
    default:
      return state;
  }
}
