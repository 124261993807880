export const FETCH_USER = 'fetch_user';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';

export const USER_INFO_FETCHED = 'USER_INFO_FETCHED';

export const MEDIA_FEED_FETCHED = 'MEDIA_FEED_FETCHED';
export const SET_MEDIA_FEED = 'SET_MEDIA_FEED';

export const MEDIA_FETCHED = 'MEDIA_FETCHED';
export const SET_MEDIAS = 'SET_MEDIAS';

export const SET_HORSES = 'SET_HORSES';
export const SET_HORSE_PHOTOS = 'SET_HORSE_PHOTOS';
export const SET_HORSE_DOCUMENTS = 'SET_HORSE_DOCUMENTS';
export const SET_HORSE_VIDEOS = 'SET_HORSE_VIDEOS';
export const HORSE_FETCHED = 'HORSE_FETCHED';
export const HORSE_DOCUMENT_FETCHED = 'HORSE_DOCUMENT_FETCHED';
export const HORSE_VIDEO_FETCHED = 'HORSE_VIDEO_FETCHED';

// ADMIN

export const USER_FETCHED = 'USER_FETCHED';
export const SET_USERS = 'SET_USERS';
export const SEARCH_CLEAR_USERS = 'SEARCH_CLEAR_USERS';
export const SEARCH_CLEAR_USER = 'SEARCH_CLEAR_USER';
export const SEARCH_USER_FETCHED = 'SEARCH_USER_FETCHED';
export const SEARCH_SET_USERS = 'SEARCH_SET_USERS';