import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
  Container,
} from 'react-bootstrap';
import { fetchHorse, fetchHorsePhotos } from '../../../actions/horseActions';
import SaleVideoCard from './core/SaleVideoCard';
import HorsePedigree from './core/HorsePedigree';
import _ from 'lodash';

import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lightgallery-bundle.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import '../../App.css';
import { cardTheme } from '../../core/Styling.js';

export default function SaleHorsePage() {
  const lightGallery = useRef(null);

  const [container, setContainer] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchHorse(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(fetchHorsePhotos(id));
    }
  }, [dispatch, id]);

  const salehorse = useSelector((state) => state.horses[id]);

  function getSaleHorseName() {
    if (salehorse) {
      return salehorse.name;
    }
    return 'loading';
  }

  function getBriefDescription() {
    if (salehorse) {
      return <h5 className="card-content-text">{salehorse.description}</h5>;
    }
    return '';
  }

  function formatAsDollars(dollarValue) {
    if (dollarValue) {
      let formatting_options = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      };
      let dollarString = new Intl.NumberFormat('en-US', formatting_options);
      return dollarString.format(dollarValue);
    }
  }

  function getGenderText(gender) {
    if (gender) {
      if (gender === 0) return 'No Gender Selected';
      if (gender === 1) return 'Gelding';
      if (gender === 2) return 'Mare';
      if (gender === 3) return 'Stallion';
    }
    return '';
  }

  function getGeneralInformation() {
    if (salehorse) {
      return (
        <ListGroup>
          <ListGroupItem className="card-content-text">
            Price: {formatAsDollars(salehorse.price)}
          </ListGroupItem>
          <ListGroupItem className="card-content-text">
            Breed: {salehorse.breed}
          </ListGroupItem>
          <ListGroupItem className="card-content-text">
            Gender: {getGenderText(salehorse.gender)}
          </ListGroupItem>
          <ListGroupItem className="card-content-text">
            Color: {salehorse.color}
          </ListGroupItem>
          <ListGroupItem className="card-content-text">
            Height: {salehorse.height}
          </ListGroupItem>
          <ListGroupItem className="card-content-text">
            Birth Year: {salehorse.birthYear}
          </ListGroupItem>
        </ListGroup>
      );
    }
    return <ListGroup></ListGroup>;
  }

  function getPhotoItems() {
    if (salehorse) {
      const photos = salehorse.photos;
      if (photos) {
        return _.values(photos).map((photo) => {
          return (
            <div>
              <img src={photo.amazons3URL} alt="" />
            </div>
          );
        });
      }
    } else {
      return null;
    }
  }

  function getGallery() {
    return (
      <Carousel swipeable={true} dynamicHeight={true} emulateTouch={true}>
        {getPhotoItems()}
      </Carousel>
    );
  }

  function getGalleryPhotoArray() {
    if (salehorse) {
      const photos = salehorse.photos;
      if (photos) {
        return _.values(photos).map((photo) => {
          return {
            id: photo.id,
            src: photo.amazons3URL,
            thumb: photo.amazons3URLThumb,
          };
        });
      }
    } else {
      return null;
    }
  }

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
      lightGallery.current.openGallery();
    }
  }, []);

  const setContainerRef = useCallback((node) => {
    if (node !== null) {
      setContainer(node);
    }
  }, []);

  const getLgComponent = () => {
    if (salehorse) {
      const photos = salehorse.photos;
      if (photos) {
        var photoArray = getGalleryPhotoArray();
        if (container !== null && photoArray !== null) {
          return (
            <LightGallery
              plugins={[lgZoom, lgThumbnail]}
              container={container}
              elementClassNames="custom-classname"
              dynamic
              dynamicEl={photoArray}
              closable={false}
              showMaximizeIcon
              onInit={onInit}
            ></LightGallery>
          );
        }
      }
    }
    return '';
  };

  function renderVideos() {
    if (salehorse && salehorse.videos && salehorse.videos.length > 0) {
      let videosArray = salehorse.videos.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : -1
      );
      return videosArray.map((video) => {
        return (
          <ListGroupItem>
            <SaleVideoCard video={video} key={video.id} />
          </ListGroupItem>
        );
      });
    } else {
      return (
        <div>
          <h4 className="card-header-text">Videos to come</h4>
        </div>
      );
    }
  }

  function videosBody() {
    return <ListGroup>{renderVideos()}</ListGroup>;
  }

  const videosPanel = (
    <Card bg={cardTheme}>
      <Card.Header>
        <h3 className="card-header-text">Videos</h3>
      </Card.Header>
      <Card.Body>{videosBody()}</Card.Body>
      <Card.Footer />
    </Card>
  );

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className="rounded mb-3">
          <Card bg={cardTheme}>
            <Card.Header className="mx-auto">
              <Card.Title>
                <h2 className="card-header-text">{getSaleHorseName()}</h2>
              </Card.Title>
            </Card.Header>
          </Card>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title className="card-header-text" componentClass="h2">
                Description
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>{getBriefDescription()}</ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12} className="nav-item-text">
          {videosPanel}
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title componentClass="h2" className="card-header-text">
                General Information
              </Card.Title>
            </Card.Header>
            <Card.Body>{getGeneralInformation()}</Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title componentClass="h2" className="card-header-text">
                Pedigree
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <HorsePedigree horse={salehorse} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12} className="d-none d-lg-block">
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title>
                <h3 className="card-header-text">Photos</h3>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <div
                style={{ width: '100%', height: '1000px' }}
                ref={setContainerRef}
                className="d-flex justify-content-center"
              ></div>
              {getLgComponent()}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className="d-lg-none">
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title>
                <h3 className="card-header-text">Photos</h3>
              </Card.Title>
            </Card.Header>
            {getGallery()}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
