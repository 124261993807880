import React from 'react';
import { Image, Button } from 'react-bootstrap';
import moment from 'moment';
import ModalImage from 'react-modal-image';

import '../../../App.css';

export default function HorsePhotoCard({ horsePhoto, onDelete, onMakeMainPhoto }) {
  function dateToText(date) {
    return <p>{moment(date).format('M/D/YY - hh:mm a')}</p>;
  }

  function renderImgTag() {
    if (
      horsePhoto &&
      horsePhoto.amazons3URLHalf &&
      horsePhoto.amazons3URLHalf.length > 0
    ) {
      return (
        <ModalImage
          small={horsePhoto.amazons3URLHalf}
          medium={horsePhoto.amazons3URLHalf}
          large={horsePhoto.amazons3URL}
          alt="Hello World!"
          key={horsePhoto.id}
        />
      );
    }
    if (
      horsePhoto &&
      horsePhoto.amazons3URL &&
      horsePhoto.amazons3URL.length > 0
    ) {
      return (
        <ModalImage
          small={horsePhoto.amazons3URL}
          medium={horsePhoto.amazons3URL}
          alt="Hello World!"
          key={horsePhoto.id}
        />
      );
    } else {
      return (
        <Image src="/images/photos/blank.png" alt="Avatar" rounded responsive />
      );
    }
  }

  function getDisplayName() {
    if (horsePhoto && horsePhoto.title) {
      return horsePhoto.title;
    } else {
      if (horsePhoto && horsePhoto.description) {
        return horsePhoto.description;
      } else {
        return 'id:' + horsePhoto.id;
      }
    }
  }

  function getUploadDate() {
    if (horsePhoto && horsePhoto.createdAt) {
      return dateToText(horsePhoto.createdAt);
    } else {
      return 'Unknown Date';
    }
  }

  function handleDelete(e) {
    if (e) {
      e.preventDefault();
    }
    const horsePhotoId = horsePhoto.id;
    const horseId = horsePhoto.horseId;
    onDelete({ horseId, horsePhotoId });
  };

  function getDeleteButton() {
    if (onDelete) {
      return (
        <Button onClick={handleDelete} variant="danger">
          Delete
        </Button>
      );
    }
  }

  function handleMakeMainPhoto(e) {
    if (e) {
      e.preventDefault();
    }
    const photoURL = horsePhoto.amazons3URL;
    const horseId = horsePhoto.horseId;
    onMakeMainPhoto({ horseId, photoURL });
  }

  function getMakeMainPhotoButton() {
    if (onMakeMainPhoto) {
      return (
        <Button onClick={handleMakeMainPhoto} variant="info">
          Make Main Photo
        </Button>
      );
    }
  }

  return (
    <td>
      <div className="card">
        {renderImgTag()}
        <div className="container">
          <h4 className="card-header-text">{getDisplayName()}</h4>
          <h5 className="card-header-text">{getUploadDate()}</h5>
          <p>{getDeleteButton()}</p>
          <p>{getMakeMainPhotoButton()}</p>
        </div>
      </div>
    </td>
  );
}
